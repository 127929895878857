<template>
  <div class="passedSuccess-box">
    <div class="u-flex-col u-col-center passedSuccess-item">
      <img
        class="icon-success"
        src="../assets/images/icon_submit_success.png"
      />
      <div class="carLoanSuccess-tit">初审通过注意接听电话</div>
      <div class="tips">温馨提示：下载APP获得最高30天免息资格</div>
      <h3>为您推荐更多符合您的产品</h3>

      <!-- 产品详情 -->
      <div class="card-box-top pr u-text-left">
        <div class="u-flex u-col-center u-row-between">
          <div class="u-flex u-col-center">
            <img class="product-logo" src="../assets/images/logo.png" />
            <span class="product-title">融e钱包免息活动专享借款</span>
          </div>
          <span class="successful-loan">12.3w人借款成功</span>
        </div>

        <div class="product-item-middle u-flex u-row-between u-col-center">
          <div class="product-item-middle-left u-flex-col u-row-center">
            <span class="number-span">最高可借金额</span>

            <h5 class="product-tips">
              <count-to :start-val="0" :end-val="200000" :duration="1000" />
              <small>元</small>
            </h5>
          </div>
          <div class="u-flex-col u-row-center">
            <span class="number-span">借款期限</span>
            <h5 class="product-tips">3-24<small>月</small></h5>
          </div>
          <div class="u-flex-col u-row-center">
            <span class="number-span">综合年化</span>
            <h5 class="product-tips">6<small>%起</small></h5>
          </div>
        </div>

        <!-- 底部进度条 -->
        <div class="step-con u-flex u-col-center">
          <div class="step u-flex-1">
            <div
              class="step-line pr"
              :class="stepLineMove ? 'step-line-move' : ''"
            ></div>
          </div>
          <div>
            剩余名额
            <count-to :start-val="100" :end-val="5" :duration="1000" />
            %
          </div>
        </div>
      </div>

      <div class="btn" @click="handleDownload">领取30天免息资格</div>
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
import CountTo from "vue-count-to";
import SharePop from "@/components/SharePop";
import * as API_Common from "@/api/common.js";

export default {
  name: "PassedSuccess",
  components: {
    CountTo,
    SharePop,
  },
  data() {
    return {
      stepLineMove: false,
      channel: this.$route.query.source,
      downAppUrl: undefined,
      showPop: false,
    };
  },
  mounted() {
    this.getDownloadUrl();
    setTimeout(() => {
      this.stepLineMove = true;
    }, 1000);
  },
  methods: {
    handleDownload() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        window.location.href = this.downAppUrl;
      }
    },

    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
          if (res.data.type === 2) {
            setTimeout(() => {
              this.handleDownload();
            }, 5000);
          } else {
            this.handleDownload();
          }
        } else {
          this.$toast.fail("获取下载地址失败,请重试");
        }
      });
    },

    handlePopChange(show) {
      this.showPop = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.passedSuccess-box {
  height: 100vh;
  background: linear-gradient(180deg, #fff4e6, rgba(254, 239, 208, 0));
  padding: 0.36rem;

  .passedSuccess-item {
    padding: 0.74rem 0.34rem 0.82rem;
    background: #ffffff;
    font-size: 0.28rem;
    font-weight: 400;
    text-align: center;
    color: #292929;

    > h3 {
      font-size: 0.28rem;
      font-weight: 400;
      color: #3a3a3a;
      line-height: 0.4rem;
    }
  }

  .icon-success {
    width: 2.18rem;
    height: 1.76rem;
    margin-bottom: 0.32rem;
  }

  .carLoanSuccess-tit {
    margin-bottom: 0.06rem;
  }

  .tips {
    width: 100%;
    font-size: 0.24rem;
    font-weight: 400;
    text-align: center;
    color: #fb8d0b;
    background: rgba(251, 141, 11, 0.1);
    line-height: 0.6rem;
    margin: 0.36rem 0 0.72rem;
  }

  .card-box-top {
    width: 100%;
    background: #f8f8f8;
    margin: 0.3rem 0;
    padding: 0.24rem;
    overflow: hidden;
    transition: all linear 0.2s;
    border-radius: 0.16rem;
  }

  .product-logo {
    width: 0.48rem;
    height: 0.48rem;
    margin-right: 0.1rem;
    border-radius: 50%;
  }

  .product-title {
    color: #3a3a3a;
    font-size: 0.26rem;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .successful-loan {
    font-size: 0.24rem;
    font-weight: 400;
    color: #b0400f;
  }

  .product-item-middle {
    margin: 0.24rem 0;
  }

  .number-span {
    font-size: 0.24rem;
    font-weight: 400;
    color: #c3c2c6;
    margin-bottom: 0.1rem;
  }

  .product-tips {
    font-weight: 600;
    font-size: 0.4rem;
    color: #3a3a3a;
    line-height: 0.42rem;
  }

  .product-tips > small {
    margin-left: 0.05rem;
    font-size: 0.24rem;
  }

  .product-item-middle-left .product-tips {
    color: #fb8d0b;
  }

  .step-con {
    margin-top: 0.24rem;
    width: 100%;
    font-size: 0.22rem;
    color: #ff2422;
  }

  .step {
    margin-right: 0.24rem;
    height: 0.18rem;
    background: #ffbdac;
    border-radius: 0.12rem;
  }

  .step-line {
    min-width: 1%;
    width: 1%;
    height: 100%;
    border-radius: 0.6rem;
    animation: warning-animation 5s infinite linear;
    background: linear-gradient(
      -45deg,
      #fff8ee 25%,
      #db3d08 0,
      #eb430a 50%,
      #ff2422 0,
      #ffffff 75%,
      #ff2422 0
    );
    background-size: 0.16rem 0.16rem;
  }

  .step-line-move {
    width: 90%;
    transition: width 2s linear;
  }

  .step-line:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    border-radius: 0.24rem;
    background-image: linear-gradient(
      to bottom,
      #fddca4,
      rgba(243, 243, 229, 60%) 15%,
      transparent 60%,
      #f3f9e8
    );
  }

  @keyframes warning-animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 1rem 0;
    }
  }

  .btn {
    width: 100%;
    background: #fb8d0b;
    border-radius: 0.16rem;
    font-size: 0.32rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.84rem;
  }
}
</style>
